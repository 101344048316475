body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 2.15rem;
  pointer-events: none;
  margin-right: 0.5rem;
}

.App-image {
  max-width: 60%;
}

/* App image style for mobile devices */
@media only screen and (max-width: 600px) {
  .App-image {
    max-width: 90%;
  }
  .App-logo {
    height: 2rem;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
  background-color: #9ce2f9;
 font-family: 'Pingsan', sans-serif;
  padding-top: 5rem;
}

.App-link {
  color: #2eb4e0;
}

/* Style for mobile devices */
@media only screen and (max-width: 600px) {
  .App-header {
    font-size: calc(20px + 2vmin);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* app-nav style */
.app-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  gap: 2rem;
  background-color: #4190aa;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0.5rem;
}

/* site-title style */
.site-title {
  font-family: 'Tomahawk MF Regular', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  display: flex;
}

/* .app-nav ul style */
.app-nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  gap: 1rem;
  font-size: 2.25rem;
}

/* .app-nav a style */
.app-nav a {
  font-family: 'Pingsan', sans-serif;
  color: #7FC4A2;
  text-decoration: none;
  color: inherit;
  height: 100%;
  align-items: center;
  padding: .25rem;
}

.app-nav li:hover {
  background-color: #c8e1fb;
  color: #4190aa;
  border-radius: 5px;
}
/*
.app-nav li.active {
  background-color: #c8e1fb;
  color: #4190aa;
  border-radius: 5px;
}
*/

/* Navbar style for mobile devices */
@media only screen and (max-width: 800px) {
  .App-logo {
    max-width: 2rem;
    height: auto;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .App-name-nav {
    display: none;
  }

  .app-nav {
    gap: 1rem;
  }

  .app-nav ul {
    gap: 1rem;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 10rem;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
  background-color: #9ce2f9;  /* Improve text readability */
  font-family: 'Pingsan', sans-serif;
  /* Even moooore text readability */
  font-size: 2rem;
}

.about-heading {
  font-size: 5rem;
  font-weight: bold;
}

.about-description {
  font-size: 3rem;
  font-weight: normal;
}

/* Style for mobile devices */
@media only screen and (max-width: 600px) {
  .about-container {
    padding: 1rem;
  }

  .about-heading {
    font-size: 2.25rem;
  }

  .about-description {
    font-size: 1.75rem;
    padding: 0rem;
  }
}


.footer {
  background-color: #4190aa;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;

}


.footer-info {
  font-size: 2rem;
  font-weight: normal;
  align-items: center;
}

.footer-info ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  gap: 1rem;
  justify-content: center;

}

.footer-link {
  font-size: 2.5rem;
  color: #7FC4A2;
  text-decoration: none;
  color: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.phantom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
  font-family: 'Pingsan', sans-serif;
  padding-top: 5rem;
  min-height: 100%;
  padding-bottom: 5rem;
}

.phantom-heading {
  font-size: 5rem;
  font-weight: bold;
}

.phantom-button {
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem;
  background-color: #59488e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Pingsan', sans-serif;
  margin-left: 2.5rem;
}

.phantom-input {
  font-size: 2rem;
  padding: 1rem;
  border: 1px solid #59488e;
  border-radius: 5px;
  font-family: 'Pingsan', sans-serif;
  margin-left: 2.5rem;
}

.phantom-button:disabled {
  background-color: #1b0f40;
}


.App-icon {
  height: 3rem;
  pointer-events: none;
}

.Phantom-icon {
  height: 2rem;
  pointer-events: none;
}

@media only screen and (max-width: 1200px) {
  .Nav-text {
    display: none;
  }
}

/*Style for class "App-nav Phantom-nav"  */
.app-nav.phantom {
  background-color: #59488e;
  color: white;
}

.footer.phantom {
  background-color: #59488e;
  color: white;
}

.active.phantom {
  background-color: #a087e9;
  color: white;
  border-radius: 5px;
}

.app-nav.phantom li:hover {
  background-color: #a087e9;
  color: #59488e;
  border-radius: 5px;
}

.roadmap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
  background-color: #9ce2f9;  /* Improve text readability */
  font-family: 'Pingsan', sans-serif;
  /* Even moooore text readability */
  font-size: 2rem;
}

@media screen and (max-width: 1200px) {
  .phantom-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 2rem;
  }
  .phantom-heading {
    font-size: 3rem;
  }
  .phantom-input {
    font-size: 1.5rem;
    padding: 0.5rem;
    max-width: 80%;
  }
  .phantom-button {
    font-size: 1.5rem;
    padding: 0.5rem;
    margin-left: 0;
    max-width: 80%;
  }
}